<template>
  <g :id="`Male55AvatarMobile_${uniqueIdSuffixA}`">
    <g>
      <circle style="fill: #d0d0d0" cx="68.5" cy="135.3" r="62.7" />
    </g>
    <g>
      <g>
        <path
          :style="{ fill: ringColorHex }"
          :stroke="ringColorHex ? 'rgba(255,255,255,0.5)' : ''"
          d="M68.5,198.9c-35.1,0-63.6-28.5-63.6-63.6s28.5-63.6,63.6-63.6c35.1,0,63.6,28.5,63.6,63.6
				S103.6,198.9,68.5,198.9z M68.5,82.2c-29.3,0-53.1,23.8-53.1,53.1s23.8,53.1,53.1,53.1s53.1-23.8,53.1-53.1S97.8,82.2,68.5,82.2z
				"
        />
      </g>
    </g>
    <g>
      <defs>
        <circle
          :id="`SVGID_123456_${uniqueIdSuffixA}`"
          cx="68.5"
          cy="135.3"
          r="50.9"
        />
      </defs>
      <clipPath :id="`SVGID_7890_${uniqueIdSuffixB}`">
        <use
          :xlink:href="`#SVGID_123456_${uniqueIdSuffixA}`"
          style="overflow: visible"
        />
      </clipPath>
      <g :style="`clip-path: url(#SVGID_7890_${uniqueIdSuffixB})`">
        <g>
          <g>
            <path
              style="fill: #edc593"
              d="M82.3,148.7L82.3,148.7C82.3,148.8,82.3,148.8,82.3,148.7c-2,1.6-4.4,2.7-6.9,3.3H64.1
						c-2.8-0.5-5.4-1.6-7.5-3.2v0c-3.9-2.9-6.4-7.3-6.4-12.8v-1.4l0.9-6.2l0.6-1.5c0,0-0.2-6,1-11.9c1.2-5.7,3.7-11.3,8.9-11.4
						c2.5,0,4.9,0.7,8.4,0.8c3.5,0.1,4.7-1,7.7-0.8c5.1,0.3,7.6,5.7,8.8,11.2c1.3,6,1.1,12.1,1.1,12.1l0.6,1.5l0.5-6L89,123h2
						c1.9,0,2.2,1.5,2.2,3.4v3.6c0,1.9-0.8,2.6-2.2,3.4c-0.6,0.3-2.4,1.2-2.4,1.2h-0.2v1.4C88.5,141.4,86.1,145.9,82.3,148.7z
						 M71.3,130.8c0.6-0.6,1.4-1.7,1.3-3.7c0-0.7-0.6-1.2-1.3-1.2c-0.7,0-1.2,0.6-1.2,1.3c0,0.8-0.1,1.4-0.5,1.7
						c-0.6,0.5-1.5,0.5-1.9,0.5c-0.7-0.1-1.3,0.4-1.4,1.1c-0.1,0.7,0.4,1.3,1.1,1.4c0.1,0,0.2,0,0.5,0
						C68.8,132,70.2,131.8,71.3,130.8z"
            />
            <path
              style="fill: #d3ae84"
              d="M72.6,127.1c0.1,1.9-0.7,3.1-1.3,3.7c-1.1,1-2.5,1.2-3.3,1.2c-0.3,0-0.5,0-0.5,0
						c-0.7-0.1-1.2-0.7-1.1-1.4c0.1-0.7,0.7-1.2,1.4-1.1c0.3,0,1.3,0,1.9-0.5c0.4-0.3,0.5-0.9,0.5-1.7c0-0.7,0.5-1.2,1.2-1.3
						C72,125.9,72.6,126.5,72.6,127.1z"
            />
            <path
              style="fill: #f5f5f5"
              d="M59.3,177.7l-8.6-13.2l0.2-0.3l1-0.3c2.3-0.6,4.6-2.6,4.6-4.9v-1.8l12.6,11.7l-4.7,4.3
						L59.3,177.7z"
            />
            <path
              style="fill: #8e6f50"
              d="M87.6,126.9c0,0,0.2-6.1-1.1-12.1l1.9-1.2c0,0.4,0,0.8,0,1.2l0.3,7.2l0,0.4l-0.5,6L87.6,126.9z"
            />
            <path
              style="fill: #edc593"
              d="M50.1,134.7c0,0-1.8-0.9-2.4-1.2c-1.4-0.8-2.2-1.5-2.2-3.4v-3.6c0-1.9,0.3-3.4,2.2-3.4h2.4
						l1,5.5l-0.9,6.2H50.1z"
            />
            <path
              style="fill: #333333"
              d="M38.3,278.1h-0.4h-0.2l-5.3-1.7l-2.3-0.7l-5.8-1.8v0l-0.1-1.1l1-95.9c1.2-3.9,4.4-6.9,8.3-7.9
						l7.1-1.9l10.2-2.8l-0.2,0.3l12.1,40.7l-4.6,36.5l11.3,17.6l11-17.6l-4.5-36.3l12-40.4l0.3-0.4l-0.2-0.3l10.2,2.8l7.1,1.9
						c4,1,7.1,4,8.3,7.9l1,95.9l-0.1,1.1v0l-5.8,1.8l-2.3,0.7l-5.3,1.7H101h-0.4l0,14.7H38.3L38.3,278.1z"
            />
            <path
              style="fill: #f5f5f5"
              d="M74.6,173.3l-4.9-4.4l12.6-11.7v1.8c0,2.3,2.3,4.3,4.6,4.9l1,0.3l0.2,0.3l-0.3,0.4l-8.4,12.8
						L74.6,173.3z"
            />
            <path
              style="fill: #f5f5f5"
              d="M83,156.7l5,7.5l-1-0.3c-2.3-0.6-4.6-2.6-4.6-4.9v-1.8L83,156.7z"
            />
            <path
              style="fill: #8e6f50"
              d="M50.1,123l0.1-0.7l0-7.5c0-0.5,0-0.9,0.1-1.4l2.5,1.6c-1.2,5.9-1,11.9-1,11.9l-0.6,1.5L50.1,123
						z"
            />
            <polygon
              style="fill: #ececec"
              points="87.9,164.9 75.9,205.3 72.6,178.4 72.9,178.4 74.6,173.3 79.5,177.7 					"
            />
            <polygon
              style="fill: #3b79af"
              points="74.6,173.3 72.9,178.4 72.6,178.4 66.1,178.4 64.3,173.2 69.1,168.9 69.8,168.9 					
						"
            />
            <path
              style="fill: #d3ae84"
              d="M82.4,149v8.2l-12.6,11.7h-0.7l-12.6-11.7V149l0.2-0.2c2.1,1.6,4.7,2.7,7.5,3.2h11.2
						c2.6-0.6,4.9-1.7,6.9-3.2l0,0L82.4,149z"
            />
            <path
              style="fill: #f5f5f5"
              d="M56.4,157.2v1.8c0,2.3-2.3,4.3-4.6,4.9l-1,0.3l5-7.5L56.4,157.2z"
            />
            <polygon
              style="fill: #ececec"
              points="64.3,173.2 66.1,178.4 62.7,205.2 50.7,164.5 59.3,177.7 					"
            />
            <polygon
              style="fill: #333333"
              points="75.9,205.3 80.5,241.6 69.4,259.2 58.2,241.6 62.7,205.2 69.3,227.5 					"
            />
            <path
              style="fill: #8e6f50"
              d="M88.5,113.6l-1.9,1.2c-1.2-5.5-3.7-10.9-8.8-11.2c-3-0.2-4.3,0.9-7.7,0.8
						c-3.6-0.1-5.9-0.8-8.4-0.8c-5.2,0.1-7.7,5.7-8.9,11.4l-2.5-1.6c0.7-9.1,8.3-16.3,17.6-16.3h2.9
						C80.2,97.1,87.8,104.4,88.5,113.6z"
            />
            <polygon
              style="fill: #4080c1"
              points="72.6,178.4 75.9,205.3 69.3,227.5 62.7,205.2 66.1,178.4 					"
            />
          </g>
          <g> </g>
        </g>
        <path
          style="fill: #8e6f50"
          d="M69.8,147.8c0.5,0.1,0.9,0.5,0.9,1.1c0.1,1.1,0.3,2.9,1.2,2.9c1.4,0,4.6-7.1,4.5-8.3
				c-0.1-1.2-5.4-4.9-6.1-5.1c-0.2-0.1-0.2-0.2-0.1-0.5c0.3-1,1.4-1.4,2.2-1c2.5,1.2,6.6,4.2,6.9,5.2c0.8,3.3-3,10.4-4,11.9
				s-6.2,1.3-6.2,1.3s-5.2,0.2-6.2-1.3s-4.8-8.6-4-11.9c0.2-1,4.4-4,6.9-5.2c0.9-0.4,1.9,0,2.2,1c0.1,0.2,0.1,0.4-0.1,0.5
				c-0.7,0.2-6.1,3.9-6.1,5.1c-0.1,1.2,3.1,8.3,4.5,8.3c0.9,0,1.2-1.8,1.2-2.9c0-0.6,0.4-1,0.9-1.1H69.8z"
        />
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    ringColor: {
      type: String,
      default: "#b5b4b5",
    },
  },
  computed: {
    ringColorHex() {
      return this.ringColor;
    },
    uniqueIdSuffixA() {
      return Math.floor(Math.random() * (25 - 2) + 2);
    },
    uniqueIdSuffixB() {
      return Math.floor(Math.random() * (50 - 26) + 62);
    },
  },
};
</script>
